import {
  SegmentControlTabs,
  DocketDisplayItemType,
  DocketHeaderColor,
  Scale,
} from './types';
import { DocketStatus, OrderTypeCode } from '@oolio-group/domain';
import theme from './theme';

export const ORDER_TYPES_STORAGE_KEY = 'ORDER_TYPES';
export const SALES_CHANNEL_STORAGE_KEY = 'SALES_CHANNEL';
export const ORG_TIMINGS_STORAGE_KEY = 'ORG_TIMINGS';

export const LOG_ROCKET_ID = 'ypqfwn/kds-app';
export const LOG_ROCKET_TRACKING_BUZA_MC_ORG_ID =
  '28064-23da-40c2-9915-9630466efcbb';

export const DELAY_TO_BUMP = 500;
export const DEVICE_CODE_LENGTH = 8;
export const SECONDS_IN_TWO_HOUR = 60 * 60 * 2;

export const DOCKETS = 'DOCKETS';
export const CONTINUE = 'Continue';
export const VOIDED_TEXT = 'VOIDED';
export const RECALL_TEXT = 'RECALL';
export const RECALLED_TEXT = 'RECALLED';

export const IOS_SCALE = 0.8;
export const LOGO_RATIO = 0.26;
export const DOCKET_WIDTH = 294;
export const INTERFACE_HEIGHT = 176;

export const DefaultReceiveMessage =
  'Hi {{customer}}, your {{orderType}} order at {{store}} is confirmed. You can view your receipt here: {{receiptLink}}.';
export const DefaultBumpMessage =
  'Hi {{customer}}, your {{orderType}} order at {{store}} is ready to be picked up. You can view your receipt here: {{receiptLink}}.';

export enum ScaleFactor {
  SMALL = 1,
  MEDIUM = 1.12,
  LARGE = 1.25,
}

export const TEXT_SIZE_OPTIONS = [
  { value: Scale.SMALL, label: 'Small' },
  { value: Scale.MEDIUM, label: 'Medium' },
  { value: Scale.LARGE, label: 'Large' },
];

export const DocketDisplayItemTypeHeight = {
  [DocketDisplayItemType.DOCKET_HEADER]: 80,
  [DocketDisplayItemType.VOID]: 54,
  [DocketDisplayItemType.DUE_AT]: 54,
  [DocketDisplayItemType.ORDER_NOTE]: 54,
  [DocketDisplayItemType.RECALLED_TAG]: 54,
  [DocketDisplayItemType.COURSE]: 54,
  [DocketDisplayItemType.ITEM]: 53,
  [DocketDisplayItemType.ITEM + 'NOTE']: 26,
  [DocketDisplayItemType.ITEM + 'MODIFIER']: 26,
  [DocketDisplayItemType.ITEM + 'VARIANT']: 26,
  [DocketDisplayItemType.CONTINUE]: 48,
  [DocketDisplayItemType.RECALL_BUTTON]: 45,
  [DocketDisplayItemType.TRANSFERRED_LABEL]: 54,
};

export const options: SegmentControlTabs[] = [
  {
    label: 'Open',
    value: DocketStatus.CREATED,
  },
  {
    label: 'Completed',
    value: DocketStatus.COMPLETED,
  },
];

export const DOCKET_HEADER_COLOR: DocketHeaderColor = {
  [OrderTypeCode.DELIVERY]: theme.colors.blue,
  [OrderTypeCode.PICK_UP]: theme.colors.blue,
  [OrderTypeCode.TAKEAWAY]: theme.colors.blue,
  [OrderTypeCode.CATERING]: theme.colors.deepPurple,
  [OrderTypeCode.DINE_IN]: theme.colors.deepPurple,
};

// REPLACE
export const FooterHeight = 76;
export const HeaderHeight = 76;
export const TextLineHeight = 20;
export const TextCharacterCount = 28;

export enum SortingPreference {
  NEWEST_FIRST = 'NEWEST_FIRST',
  OLDEST_FIRST = 'OLDEST_FIRST',
  TABLE_NAME = 'TABLE_NAME',
}

export const FEATURES = {
  ALL_DAY_VIEW: 'KdsViewCounts',
  DOCKET_PRINTING: 'DocketPrinting',
  DOCKETS_RECEIVED_ACK: 'DocketReceivedAck',
};
