import {
  DocketDisplayItem,
  DocketDisplayItemType,
  DocketDisplayOrder,
  DocketDisplayOrderItem,
} from '../../common/types';
import React from 'react';
import {
  OrderType,
  DocketStatus,
  SalesChannel,
  DocketItemStatus,
  Docket,
} from '@oolio-group/domain';
import {
  getOrderTypeName,
  getDockerHeaderTitle,
  getHeaderColorByOrderType,
  getPartnerSalesChannelName,
} from '../../utils/docketHelper/transformDockets';
import DocketItemHeader from './DocketItemEntities/DocketItemHeader';
import DocketDisplayItemComponent from './DocketItemEntities/DocketDisplayItem';
import DocketItemBase from './DocketItemEntities/DocketItemBase';
import DocketDueTime from './DocketItemEntities/DocketDueTime';
import DocketItemContinue from './DocketItemEntities/DocketItemContinue';
import DocketItemReCall from './DocketItemEntities/DocketRecallButton';
import DocketVoidLabel from './DocketItemEntities/DocketVoidLabel';
import DocketTransferredLabel from './DocketItemEntities/DocketTransferredLabel';
import DocketRecalledLabel from './DocketItemEntities/DocketRecalledLabel';
import DocketItemNotes from './DocketItemEntities/DocketItemNotes';

export interface DocketItemProps {
  item?: DocketDisplayItem;
  status: DocketStatus;
  onPressDocketHeader?: (docketId: string) => Promise<Docket>;
  onPressDocketItem?: (
    docketItemId: string,
    itemStatus: DocketItemStatus,
  ) => Promise<void>;
  orderTypes: OrderType[];
  salesChannels?: SalesChannel[];
  enablePrint?: boolean;
  printKitchenDocket?: (docket: string, status: DocketStatus) => Promise<void>;
}

const DocketItem: React.FC<DocketItemProps> = ({
  item,
  status,
  onPressDocketHeader,
  onPressDocketItem,
  orderTypes,
  salesChannels = [],
  enablePrint,
  printKitchenDocket,
}) => {
  if (!item) return null;

  switch (item.type) {
    case DocketDisplayItemType.DOCKET_HEADER:
      const docket = item as DocketDisplayOrder;
      const docketName = getDockerHeaderTitle({
        orderTypes: orderTypes,
        orderTypeId: docket.orderType,
        tableName: docket.table,
        sectionName: docket.section,
        customerName: docket.customer,
        source: docket.source,
        orderNumber: docket.orderNumber,
      });
      //TODO to get ordertype name in kds event
      const orderTypeName = getOrderTypeName(docket.orderType, orderTypes);
      const salesChannelName = getPartnerSalesChannelName(
        docket.salesChannel,
        salesChannels,
      );
      const docketHeaderColor = getHeaderColorByOrderType(
        docket.orderType,
        orderTypes,
      );
      const handleOnPressDocketHeader = async () => {
        if (onPressDocketHeader) {
          await onPressDocketHeader(docket.id);
        }
      };
      return (
        <DocketItemHeader
          docketId={docket.id}
          onPressDocketHeader={handleOnPressDocketHeader}
          orderTypeName={orderTypeName}
          salesChannelName={salesChannelName}
          orderNumber={docket.orderNumber}
          docketName={docketName}
          createdTime={docket.time}
          status={status}
          docketHeaderColor={docketHeaderColor}
          docketStatus={docket.status}
          enablePrint={enablePrint}
          printKitchenDocket={printKitchenDocket}
        />
      );
    case DocketDisplayItemType.DUE_AT:
      return <DocketDueTime name={item.name} status={status} />;
    case DocketDisplayItemType.ITEM:
      const docketItem = item as DocketDisplayOrderItem;
      const handleOnPressDocketItem = async () => {
        if (onPressDocketItem) {
          await onPressDocketItem(
            docketItem?.orderItemId,
            docketItem.itemStatus,
          );
        }
      };
      return (
        <DocketDisplayItemComponent
          id={docketItem.id}
          quantity={docketItem.quantity}
          name={docketItem.name}
          note={docketItem.note}
          modifiers={docketItem.modifiers}
          variant={docketItem.variant}
          itemStatus={docketItem.itemStatus}
          status={status}
          onPressDocketItem={handleOnPressDocketItem}
        />
      );
    case DocketDisplayItemType.COURSE:
      return <DocketItemBase name={item.name} />;
    case DocketDisplayItemType.ORDER_NOTE:
      return <DocketItemNotes name={item.name} />;
    case DocketDisplayItemType.VOID:
      return <DocketVoidLabel />;
    case DocketDisplayItemType.TRANSFERRED_LABEL:
      return <DocketTransferredLabel tableId={item.name} />;
    case DocketDisplayItemType.RECALLED_TAG:
      return <DocketRecalledLabel />;
    case DocketDisplayItemType.CONTINUED:
      // TODO Need to handle the 2/2 continue tag
      return (
        <DocketItemContinue text={item.name} subtext={'2/2'} status={status} />
      );
    case DocketDisplayItemType.CONTINUE:
      return (
        <DocketItemContinue text={item.name} subtext={'1/2'} status={status} />
      );
    case DocketDisplayItemType.RECALL_BUTTON:
      const handleOnPressDocketRecall = () => {
        const docket = item as DocketDisplayOrder;
        if (onPressDocketHeader) {
          onPressDocketHeader(docket.id);
        }
      };
      return <DocketItemReCall onPressRecall={handleOnPressDocketRecall} />;
    default:
      return null;
  }
};

export default DocketItem;
