import React from 'react';
import { View, Text } from 'react-native';
import DocketItemStyles from '../DocketItem.styles';
import { useSession } from '../../../hooks/useSession';
import { keyBy } from 'lodash';
import { useTranslation } from '@oolio-group/localization';

export interface DocketItemBaseProps {
  tableId: string;
}

const DocketItemNotes: React.FC<DocketItemBaseProps> = ({ tableId }) => {
  const styles = DocketItemStyles();
  const { session } = useSession();
  const { sections } = session;
  const { translate } = useTranslation();

  const tables = keyBy(
    sections?.flatMap(section => section?.tables) ?? [],
    'id',
  );

  const tableName = tables[tableId]?.name ?? '';
  const transferredToTable = !!tableName ? `(${tableName})` : '';

  return (
    <View
      testID="item-note"
      style={[styles.statusContainer, styles.transferredContainer]}
    >
      <Text style={[styles.statusTextUppercase, styles.transferredText]}>
        {translate('order.transferred')} {transferredToTable}
      </Text>
    </View>
  );
};

export default React.memo(DocketItemNotes);
